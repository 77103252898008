
import collect from 'collect.js'

export default {
  props: {
    guests: {
      type: Array,
      required: true,
    },
  },
  computed: {
    roomsCount() {
      return collect(this.guests).count()
    },
    adultsCount() {
      return collect(this.guests).sum('adults')
    },
    childrenCount() {
      return collect(this.guests).pluck('children').collapse().count()
    },
  },
}
