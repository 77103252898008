
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return `https://api.travelor.com/v3/services/place/photo?photoreference=${this.item.image}&maxwidth=1920`
    },
  },
  methods: {
    go() {
      this.$router.push({
        path: this.localePath(this.item.router),
      })
    },
  },
}
