import { render, staticRenderFns } from "./SearchBox.vue?vue&type=template&id=68ed7e1b&scoped=true&"
import script from "./SearchBox.vue?vue&type=script&lang=js&"
export * from "./SearchBox.vue?vue&type=script&lang=js&"
import style0 from "./SearchBox.vue?vue&type=style&index=0&id=68ed7e1b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ed7e1b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Label: require('/vercel/path0/components/common/Label.vue').default,Search: require('/vercel/path0/components/partials/Search.vue').default,Button: require('/vercel/path0/components/common/Button.vue').default,Popup: require('/vercel/path0/components/common/Popup.vue').default,Input: require('/vercel/path0/components/common/Input.vue').default,Hotel: require('/vercel/path0/components/cards/Hotel.vue').default,Stars: require('/vercel/path0/components/partials/Stars.vue').default,Dates: require('/vercel/path0/components/partials/Dates.vue').default,Guests: require('/vercel/path0/components/partials/Guests.vue').default,Select: require('/vercel/path0/components/common/Select.vue').default})
